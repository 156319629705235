import React, { useContext, useEffect, useState } from "react";
import { initiateLLMPreset, pollLLMPreset } from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import Modal from "../../components/Modal/Modal";
import { Loader } from "../../components/Loader/Loader";
import ErrorIcon from "@mui/icons-material/Error";
import { captureSentryError } from "../../util/sentry";

const llmSummaryConfig = [
  { name: "Tell me about my detections", value: "1" },
  { name: "Tell me about my vulnerabilties", value: "2" },
];

const LlmSummary = () => {
  const [userData] = useContext(UserData);
  const [summarySelection, setSummarySelection] = useState();
  const [llmSummaryData, setLlmSummaryData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const pollSummaryData = async (pollingId) => {
    try {
      const results = await pollLLMPreset(userData?.tenant, pollingId);
      if (results.postprocess_answer) {
        setLlmSummaryData(results.postprocess_answer);
        setLoading(false);
      } else if (results.llm_answer) {
        setError(true);
        setLoading(false);
      } else {
        setTimeout(async () => {
          pollSummaryData(pollingId);
        }, 5000);
      }
    } catch (e) {
      setLoading(false);
      captureSentryError(e, userData, "azkg llm2 pollLLMPreset API in LlmSummary.js");
    }
  };

  useEffect(() => {
    const fetchSummaryData = async () => {
      try {
        setError(false);
        setLoading(true);
        const results = await initiateLLMPreset(userData?.tenant, {
          summarization_usecase: summarySelection?.value,
        });
        pollSummaryData(results);
      } catch (e) {
        setLoading(false);
        captureSentryError(e, userData, "azkg llm2 initiateLLMPreset API in LlmSummary.js");
      }
    };

    summarySelection && fetchSummaryData();
    // eslint-disable-next-line
  }, [summarySelection]);

  return (
    <div className="llm-summary-container">
      {llmSummaryData && (
        <Modal
          close={() => {
            setLlmSummaryData();
            setSummarySelection();
          }}
        >
          <div className="modal-header">{`Summary - ${summarySelection?.name}`}</div>
          <div className="modal-content">{llmSummaryData}</div>
        </Modal>
      )}
      <div className="llm-summary-title">Build a Summary</div>
      {error && (
        <div className="llm-error">
          <ErrorIcon className="err-icon" /> {"Error building summary"}
        </div>
      )}
      <div className="llm-summary-content">
        {loading ? (
          <Loader />
        ) : (
          llmSummaryConfig.map((item) => (
            <div
              className="llm-summary-item"
              onClick={() => setSummarySelection({ ...item })}
              key={item.value}
            >
              {`${item.name} »`}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default LlmSummary;
