import React from "react";
import {Modal} from "../../components";
import Button from "../../components/Button/Button";
import "../../styles/pages/Vulnerabilities2/Vulnerabilities/ActionComment.scss";
import {Loader} from "../../components/Loader/Loader";
import EditPencil from './config/icons/pencil.svg';
import CloseIcon from "@mui/icons-material/Close";

const ActionComment = ({
                         setCurrentComment,
                         commentValue = '',
                         close,
                         existingComment = '',
                         submitComment,
                         setExistingComment,
                         loading = false,
                         index
                       }) => (
  <Modal close={close} className="vulnerabilities new-comment-modal" noOutsideClick>
    <CloseIcon className="comment-close-icon" fontSize="small" onClick={e => close(false, e)}/>
    {loading && <Loader/>}
    {existingComment !== '' ?
      <div>
        <div className="modal-title">
          <div className="add-comment-title">Comment</div>
          <img
            className="edit-pencil"
            src={EditPencil}
            alt="edit comment"
            onClick={() => setExistingComment('', index)}
          />
        </div>
        <p className="comment-text">{existingComment}</p>
      </div>
      : <React.Fragment>
        <div className="modal-title add-comment-title">Add comment</div>
        <textarea
          placeholder="Enter comment here"
          value={existingComment === '' ? commentValue : commentValue}
          className="add-comment-input"
          onChange={e => setCurrentComment(e.target.value)}
        />
        <Button
          className="update-button comment"
          disabled={false}
          text="ADD"
          onClick={() => submitComment(commentValue, index)}
        />
      </React.Fragment>
    }
  </Modal>
);

export default ActionComment;
