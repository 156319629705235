import {postUpdateAssetsActionOrComment} from "../../../api/ApiClient";
import {captureSentryError} from "../../../util/sentry";

export const tableHeader = {
  "Asset Name": {"field": "asset_name", "header": "Asset Name", "sort": true, "defaultSortValue": false},
  "Status": {"field": "status", "header": "STATUS", "sort": true, "defaultSortValue": false},
  "Type": {"field": "os_platform_name", "header": "TYPE", "sort": true, "defaultSortValue": false},
  "Vulnerabilities": {
    "field": "vulnerability_count",
    "header": "VULNERABILITIES",
    "sort": true,
    "defaultSortValue": false
  },
  "Missing Patches": {
    "field": "missing_patch_count",
    "header": "MISSING PATCHES",
    "sort": true,
    "defaultSortValue": false
  }
}

export const filterConfig = [
  {
    STATUS: {
      filter_name: "status",
      isOpen: true,
      filters: {'active': false, 'inactive': false,}
    }
  },
  {
    'OS': {
      filter_name: "os_type",
      isOpen: true,
      filters: {'Linux': false, 'Windows': false, 'Mac': false, 'iOS': false, 'Android': false}
    },
  },
  {
    Device_Type: {
      filter_name: "device_type",
      isOpen: true,
      filters: {'Endpoint': false, 'Other': false},
      title: 'Device Type'
    }
  }, {
    'Action Status': {
      filter_name: "action",
      isOpen: true,
      filters: {
        'New Risk': false,
        'Accept Risk': false,
        'Defer Risk': false,
        'Ignore': false,
        'In-Progress': false,
      }
    }
  }
]

export const resetFilterConfig = {
  Exploit: {
    filter_name: "exploit",
    isOpen: true,
    filters: {'Yes': false, 'No': false}
  },
  STATUS: {
    filter_name: "status",
    isOpen: true,
    filters: {'active': false, 'inactive': false,}
  },
  'OS': {
    filter_name: "os_type",
    isOpen: true,
    filters: {'Linux': false, 'Windows': false, 'Mac': false, 'iOS': false, 'Android': false}
  },
  Device_Type: {
    filter_name: "device_type",
    isOpen: true,
    filters: {'Endpoint': false, 'Other': false},
    title: 'Device Type'
  },
  'Action Status': {
    filter_name: "action",
    isOpen: true,
    filters: {
      'New Risk': false,
      'Accept Risk': false,
      'Defer Risk': false,
      'Ignore': false,
      'In-Progress': false,
    }
  }
}

export const tabs = [
  {display: `VULNERABILITIES`, configName: "vulnerabilities"},
  {display: `PATCHES`, configName: "patches"}
];

export const vulnerabilitiesTableData = {
  vulnerabilities: {
    headers: {
      'vulnerability_id': 'vulnerability_id',
      'description': 'description',
      'severity': 'severity',
      action_taken: 'action_taken',
      comment: ''
    },
  },
  patches: {
    headers: {
      'vulnerability_id': 'hidden',
      'description': 'description',
      'severity': 'severity'
    }
  }
};

export const actionOptions = [
  {'Accept Risk': false},
  {'Defer Risk': false},
  {'In-Progress': false},
  {'Ignore': false},
]

export const actionTooltipConfig = {
  'Accept Risk': 'Accept Vulnerability Risk',
  'Ignore': 'Customer Opt-Out: Ignoring Vulnerability',
  'Defer Risk': 'Deferring Fix: Accepting Vulnerability Risk',
  'In-Progress': 'Fixing Vulnerability',
  'Risk Mitigated': "Automatically Marked as Risk Mitigated. Updated when they are not found during subsequent scans. This process is automated and doesn't consider factors like offline assets or unscanned endpoints.",
  'New Risk': 'Automatically Marked as New Vulnerability'
}

export const tables = [
  {
    labels: ['Asset Name', 'Hostname', 'Domain', 'IP Address', 'OS Platform Name', 'First Discovered', 'Last Observed'],
    title: 'Asset Info'
  },
  {
    labels: ['Status', 'OS Version', 'MAC Address', 'Firewall Enabled', 'EDR Version', 'Bitlocker Protection', 'Encryption'],
    title: 'Details'
  },
];

export const updateAsset = async (value, rowIndex, selectedCheckboxes, selectActionFromDropdown, vuln_id, data, userData, isMultiSelect = false, setIsLoading) => {
  let singleValue = data[rowIndex]?.vulnerability_id
  let idList = []
  if (isMultiSelect === true) {
    idList = []
    selectedCheckboxes.forEach((item, i) => {
      if (item === true) {
        let id = data[i]?.vulnerability_id
        idList.push(id)
      }
    })
    selectActionFromDropdown(value)
  } else {
    idList = [singleValue]
    selectActionFromDropdown(rowIndex, value);
  }
  setIsLoading(true)
  try {
    await postUpdateAssetsActionOrComment(userData?.tenant, vuln_id, {vulnerability_ids: idList, action: value});
  } catch (e) {
    captureSentryError(e, userData, "postUpdateAssetsActionOrComment action update");
  } finally {
    setIsLoading(false)
  }
};
