import React, {useEffect, useRef, useState} from "react";
import Row from "../../../components/Table/Row";
import SeverityImage from "../../../components/Table/SeverityImage";
import {severities} from "../config/vulnerabilitytabconfig";
import ExpandedView from "./ExpandedView";
import Tooltip from "../../../components/Tooltip/Tooltip";
import '../../../styles/pages/Vulnerabilities2/Vulnerabilities/VulnerabilitiesTable.scss';
import {adjustTooltipDirection} from "../../../util/tooltipAdjust";

const VulnerabilitiesRow = ({
                              data,
                              page,
                              mapMainRow,
                              expandedIndexes,
                              setExpandedIndexes,
                              expandAll,
                              setExpandAll,
                              index,
                              className,
                              setCurrTab
                            }) => {
  const [tooltipDirection, setTooltipDirection] = useState("bottom");
  const rowRef = useRef(null);
  const titleTextRef = useRef(null);
  const isExpanded = expandedIndexes?.includes(index) || expandAll?.includes(index);
  const [tooltipStyle, setTooltipStyle] = useState(null);

  const handleClick = (e) => {
    if (e.target.type !== 'checkbox') {
      if (!expandedIndexes.includes(index)) {
        setExpandedIndexes([...expandedIndexes, index]);
        setExpandAll(expandAll.filter(x => x !== index));
      } else {
        setExpandedIndexes(expandedIndexes.filter(x => x !== index));
      }
    }
  }

  useEffect(() => {
    adjustTooltipDirection(data?.description, titleTextRef, setTooltipDirection, setTooltipStyle);
  }, [index, data?.description]);

  return (
    <>
      <Row ref={rowRef} key={index + page} page={page} onClick={(e) => handleClick(e)} data={data}
           className={className + (isExpanded ? ' selected' : '')}>
        <td className={"Time td-value"}>
          {isExpanded ? <div className={"expand-btn minus"}>-</div> : <div className={"expand-btn"}>+</div>}
        </td>
        {Object.values([mapMainRow['Vulnerability ID'], mapMainRow['Severity'], mapMainRow['Description'],
          mapMainRow['CVSS SCORE'], mapMainRow['Exploit?'], mapMainRow['Assets']])
          .map((item, index) => {
            let value = data[item.field];
            return (
              <td key={index + item.field}
                  className={((item.field === 'Exploit?') ? 'Exploit' : item.field) + " td-value"}>
                {item.field === "Name" ?
                  <div className="title-container"><span className="text">{value}</span></div>
                  : item.field === 'severity' ?
                    <SeverityImage severities={severities} severity={data.severity}/>
                    : item.field === 'description' ?
                      <>
                        <div className="title-container">
                          <Tooltip
                            content={value}
                            className={`description-tooltip ${tooltipDirection}`}
                            direction={tooltipDirection}
                            disableTimeout={true}
                            style={tooltipStyle}
                          >
                            <span className="text invisible" ref={titleTextRef}>{value}</span>
                          </Tooltip>
                        </div>
                        <div className={"desc-value"} ref={titleTextRef}>{value?.toString()}</div>
                      </> : <div>{value?.toString()}</div>
                }
              </td>
            );
          })}
      </Row>
      {isExpanded && <ExpandedView data={data} key={index} setParentTab={setCurrTab}/>}
    </>
  );
};

export default VulnerabilitiesRow;
