export const adjustTooltipDirection = (tooltipValue, titleTextRef, setTooltipDirection, setTooltipStyle) => {
  const rect = titleTextRef.current?.getBoundingClientRect();

  if (rect) {
    const viewportHeight = window.innerHeight - 200;
    const direction = rect.bottom + 100 > viewportHeight ? "top" : "bottom";
    setTooltipDirection(direction);

    if (direction === 'top') {
      const tempTooltip = document.createElement('div');
      tempTooltip.style.cssText = 'visibility: hidden; white-space: break-spaces; height: fit-content; max-width: 600px; font-size: 14px; line-height: 1.2;';
      tempTooltip.innerHTML = tooltipValue ?? '';
      document.body.appendChild(tempTooltip);
      const { height } = tempTooltip.getBoundingClientRect();
      document.body.removeChild(tempTooltip);
      setTooltipStyle({ top: `-${height + 20}px` });
    } else {
      setTooltipStyle(null);
    }
  }
};