import React, {useContext, useState} from 'react';
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import PaginationControls from "../../components/Table/PaginationControls";
import {severities, statuses} from "./config/vulnerabilitytabconfig";
import Export from "../../components/Table/Export";
import {getVulnerabilitiesExport, getVulnerabilitiesExportList} from "../../api/ApiClient";
import {captureSentryError} from "../../util/sentry";
import UserData from "../../store/User/UserData";

const VulnerabilitiesTable = ({
                                TableRow,
                                tableHeader,
                                className,
                                tableData,
                                expandedIndexes,
                                setExpandedIndexes,
                                page,
                                setPage,
                                resetFilters,
                                sortFilters,
                                setSortFilters,
                                params,
                                setParams,
                                exportType,
                                setCurrTab
                              }) => {
  const [userData] = useContext(UserData);
  const [expandAll, setExpandAll] = useState([])
  const [pastExportsLoading, setPastExportsLoading] = useState(false)
  const [pastExportedFiles, setPastExportedFiles] = useState([])
  const [exportLoading, setExportLoading] = useState(false)
  const [exportFile, setExportFile] = useState('')

  const refreshExportedFiles = async () => {
    setPastExportsLoading(true)
    try {
      const listExportedLogsResponse = await getVulnerabilitiesExportList(userData?.tenant, {
        ...params,
        export_type: exportType
      })
      setPastExportedFiles(listExportedLogsResponse)
    } catch (e) {
      captureSentryError(e, userData, "getVulnerabilitiesExport");
    } finally {
      setPastExportsLoading(false)
    }
  }

  const onExport = async () => {
    if (exportLoading) {
      return
    }
    setExportLoading(true)
    try {
      let variables = params
      variables.hasOwnProperty('date_searched') && delete variables['date_searched']
      let results = await getVulnerabilitiesExport(userData?.tenant, {
        ...variables,
        export_type: exportType
      })
      setExportFile(results)
    } catch (e) {
      captureSentryError(e, userData, "getVulnerabilitiesExportList");
    } finally {
      setTimeout(() => {
        setExportLoading(false)
      }, 300000);
    }
  }

  return (
    <div className={"vulnerability-container new-page"}>
      <div className={tableData?.results?.length === 0 ? 'no-results flex' : 'flex'}>
        <PaginationControls
          hideExport={true}
          toggleShowDropdown={() => {}}
          resetAllFilters={resetFilters}
          className={"vulnerabilities-table new"}
          displayData={tableData}
          page={page}
          expandAll={expandAll}
          showDropdown={false}
          setExpandAll={setExpandAll}
          setPage={setPage}
          setExpandedIndexes={setExpandedIndexes}
          expandedIndexes={expandedIndexes}
          setParams={setParams}
          params={params}
          currentSearchConfig={params}
        />
        <Export
          className={'vuln-export'}
          currentSearchConfig={params}
          setParams={setParams}
          params={params}
          bauExportLoading={exportLoading}
          refresh={refreshExportedFiles}
          onExport={onExport}
          pastExportedFiles={pastExportedFiles}
          pastExportsLoading={pastExportsLoading}
          exportLoading={exportLoading}
          exportFile={exportFile}
        />
      </div>

      <Table page={className + " new-vuln"}>
        <TableHeader
          mainRow={tableHeader}
          className={"vulnerabilities"}
          setSortFilters={setSortFilters}
          sortFilters={sortFilters}
          setParams={setParams}
          params={params}
        />

        {tableData?.results?.length > 0 && tableData?.results?.map((item, i) =>
          <TableRow
            className={className}
            key={i + className}
            severities={severities}
            page={"vulnerabilities"}
            data={item}
            mapMainRow={tableHeader}
            statuses={statuses}
            setExpandedIndexes={setExpandedIndexes}
            expandedIndexes={expandedIndexes}
            expandAll={expandAll}
            index={i}
            setExpandAll={setExpandAll}
            setCurrTab={setCurrTab}
          />
        )}
      </Table>
    </div>
  );
}
export default VulnerabilitiesTable;
