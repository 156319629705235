import React, { useState, useEffect } from "react";
import "../../styles/pages/Login/App2FA.scss";
import { Auth } from "aws-amplify";
import QRCodeCanvas from "qrcode.react";
import Grid from "@mui/material/Grid";
import { Loader } from "../../components/Loader/Loader";
import ErrorIcon from "@mui/icons-material/Error";
import config from "../../config";
import { applicationIcons } from "./loginConfig";
import { setUserMFAPreference } from "./loginConfig";

const App2FA = ({ setSetupMFA, cognitoUser }) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [appError, setAppError] = useState("");
  const [qrLoading, setQrLoading] = useState(true);
  const [qrError, setQrError] = useState(false);
  const [qrCodeURL, setQrCodeURL] = useState("");
  const [totpToken, setTotpToken] = useState("");
  const [showManualQRcode, setShowManualQRcode] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    const getQRCode = async () => {
      try {
        const totp = await Auth.setupTOTP(cognitoUser);
        setTotpToken(totp);
        let qrcodeTotpUrl =
          "otpauth://totp/" +
          cognitoUser.attributes.email +
          "?secret=" +
          totp +
          "&issuer=ActZero.ai";
        if (config.env !== "prod") {
          const envCapitalized =
            config.env.charAt(0).toUpperCase() + config.env.slice(1);
          qrcodeTotpUrl = qrcodeTotpUrl + "(" + envCapitalized + ")";
        }
        setQrCodeURL(qrcodeTotpUrl);
        setQrLoading(false);
      } catch (e) {
        setQrError(true);
      }
    };
    cognitoUser && getQRCode();
    // eslint-disable-next-line
  }, [cognitoUser]);

  const handleApp2FASetup = async () => {
    setSubmitLoading(true);
    setAppError("");
    let errorMessage;
    try {
      let response;
      try {
        response = await Auth.verifyTotpToken(
          cognitoUser, // Return object from Auth.signIn()
          verificationCode // Confirmation code
        );
      } catch {
        errorMessage = "Invalid code entered";
      }
      if (response.Status === "SUCCESS") {
        setQrCodeURL("");
        const settingResponse = await setUserMFAPreference(cognitoUser);
        if (settingResponse === "SUCCESS") {
          setSetupMFA(false);
        }
      }
    } catch {
      setAppError(errorMessage || "An error has occurred");
    }
    setSubmitLoading(false);
  };

  const handleManualQRCode = () => {
    setShowManualQRcode(!showManualQRcode);
  };

  const handleCancel = () => {
    cognitoUser.signOut();
    window.location.reload();
  };

  return (
    <div className={"MFA-container"}>
      <div className="header">Two-Factor Authentication</div>
      <div className="App-2FA-container">
        <div className="mfa-subheader">
          <b>To set up two factor authentication</b>, download one of these
          supported applications onto your mobile device:
        </div>
        <div className={"totp-images"}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            {applicationIcons.map((icon) => (
              <Grid item key={icon.className}>
                <img
                  className={`icon ${icon.className}`}
                  src={icon.img}
                  alt={icon.label}
                  width={icon.width}
                />
                <p className={`label ${icon.className}`}>{icon.label}</p>
              </Grid>
            ))}
          </Grid>
        </div>
        <div className="qr-container">
          <div className="description-text">
            Scan this QR code using the authenticator app and then enter the
            resulting verification code to enable MFA.
          </div>
          <div className="the-code">
            {qrLoading ? (
              <Loader />
            ) : qrError ? (
              <span className="qr-error">{"Error generating QR code"}</span>
            ) : (
              <QRCodeCanvas value={qrCodeURL} size={200} includeMargin />
            )}
          </div>
        </div>
        <div className="manual-qr-code">
          <button
            className={`manual-qr-code-reveal`}
            onClick={() => handleManualQRCode()}
          >
            {"Setup key manually »"}
          </button>
        </div>
        <div className="verification-code-container">
          <div className="input-label">Verification code </div>
          <input
            type="text"
            value={verificationCode}
            onKeyDown={(e) => e.key === "Enter" && handleApp2FASetup()}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder="Numeric code"
          />
          {showManualQRcode && (
            <div className={`manual-qr-code`}>
              <div className="account-name">
                Account Name: {cognitoUser.attributes.email}
              </div>
              <div className={`key ${appError && "app-error"}`}>Key: {totpToken} </div>
            </div>
          )}
          <div className="confirm-btn-container">
            <button
              className="confirm-btn cancel"
              onClick={() => handleCancel()}
            >
              {"Cancel"}
            </button>
            <button className="confirm-btn" onClick={() => handleApp2FASetup()}>
              {submitLoading ? <Loader /> : "Confirm Code"}
            </button>
            {appError && (
              <div className="error-msg app">
                <ErrorIcon className="err-icon" /> {appError}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App2FA;
