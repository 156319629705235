export const pageSize = 10;

export const defaultInputs = {
  firstname: "",
  lastname: "",
  email: "",
  mobilePhone: "",
  jobTitle: "",
  userRole: "Contact Only",
  escalationType: "Secondary",
  contactType: ["Business"],
  mfaSetting: "SMS",
};

export const addInputsConfig = [
  { column: "firstname", placeholder: "FIRST NAME" },
  { column: "lastname", placeholder: "LAST NAME" },
  {
    column: "email",
    placeholder: "EMAIL",
    className: "contact",
  },
  {
    column: "mobilePhone",
    placeholder: "MOBILE PHONE",
    className: "mobilePhone",
  },
  { column: "jobTitle", placeholder: "JOB TITLE", className: "jobTitle" },
];

export const requiredParameters = {
  firstname: "Must enter first name",
  lastname: "Must enter last name",
  email: "Must enter valid email",
  mobilePhone: "Must enter mobile phone number",
  escalationType: "Must select an escalation type",
  contactType: "Must select a contact type",
  mfaSetting: "Must select a two factor authentication",
};

export const addUserCheckboxConfig = [
  {
    label: "Role",
    value: "userRole",
    options: ["Admin", "Power User", "Portal User", "Contact Only"],
    multiselect: false,
  },
  {
    label: "Escalation type",
    value: "escalationType",
    options: ["Primary", "Secondary"],
    multiselect: false,
  },
  {
    label: "Contact type",
    value: "contactType",
    options: ["Business", "Technical"],
    multiselect: true,
  }
];

export const editUserCheckboxConfig = [
  {
    label: "Role",
    value: "userRole",
    options: ["Admin", "Power User", "Portal User", "Contact Only"],
    multiselect: false,
  },
  {
    label: "Escalation type",
    value: "escalationType",
    options: ["Primary", "Secondary"],
    multiselect: false,
  },
  {
    label: "Contact type",
    value: "contactType",
    options: ["Business", "Technical"],
    multiselect: true,
  },
  {
    label: "Two factor authentication",
    value: "mfaSetting",
    options: ["SMS", "totp"],
    multiselect: false,
  },
];
