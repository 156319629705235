import LineChartLegend from "../../../components/D3/LineChart/Legend";
import LineChart from "../../../components/D3/LineChart/LineChart";
import React, {useEffect, useState} from "react";
import '../../../styles/pages/Vulnerabilities2/Overview/OverviewGraph.scss';
import {mapChartData} from "../../../util/mapChartData";
import {lineData} from "../config/overviewconfig";

const VulnerabilityGraph = ({className, values, colors, data}) => {
  const [axisData, setAxisData] = useState({x: {min: 0, max: 0}, y: {"max": 46, "min": 0}})
  const [mappedGraphData, setMappedGraphData] = useState([])

  useEffect(()=>{
    mapChartData(data, setAxisData, setMappedGraphData, values, [])
  },[data, values])

  return (
    <div className={"vuln-graph-container" + (mappedGraphData.length === 0 ? ' no-data' : '')}>
      <div className={`graph-title`}>
        TOTAL BY SEVERITY <span className="graph-subtitle">OVER TIME</span>
      </div>
      {mappedGraphData.length > 0 && <LineChartLegend
        colors={colors}
        rectPosition={400}
        textPosition={415}
        data={mappedGraphData}
      />}
      {mappedGraphData.length > 0 ?
        <LineChart
          width={750}
          plotRadius={2}
          yAxisTicks={4}
          xAxisTicks={mappedGraphData[0].values.length}
          lineData={lineData}
          data={mappedGraphData}
          className={"vulns-two-line-chart "}
          axis={axisData}
          widthOffset={.55}
          height={180}
          marginLeft={50}
          colors={colors}
        /> : <p className={"no-data"}>No Data</p>}
    </div>
  );
}

export default VulnerabilityGraph;
