import CriticalSeverity from "../../../assets/icons/critical-severity.svg";
import HighSeverity from "../../../assets/icons/high-severity.svg";
import ImportantSeverity from "../../../assets/icons/important-severity.svg";
import ModerateSeverity from "../../../assets/icons/moderate-severity.svg";
import MediumSeverity from "../../../assets/icons/medium-severity.svg";
import LowSeverity from "../../../assets/icons/low-severity.svg";
import {postUpdateActionOrComment} from "../../../api/ApiClient";
import {captureSentryError} from "../../../util/sentry";

export const tableHeader = {
  "Vulnerability ID": {
    "field": "vulnerability_id",
    "header": "VULNERABILITY ID",
    "sort": true,
    "defaultSortValue": false
  },
  "Severity": {"field": "severity", "header": "SEVERITY", "sort": true, "defaultSortValue": false},
  "Description": {"field": "description", "header": "DESCRIPTION", "sort": true, "defaultSortValue": false},
  "CVSS SCORE": {"field": "cvss_score", "header": "CVSS SCORE", "sort": true, "defaultSortValue": false},
  "Exploit?": {"field": "exploit", "header": "EXPLOIT", "sort": true, "defaultSortValue": false},
  "Assets": {"field": "asset_count", "header": "ASSETS", "sort": true, "defaultSortValue": false},
}

export const tabs = [
  {display: `AFFECTED ASSETS`, configName: "affected-assets"},
];

export const tableHeaders = {
  ipaddress: 'IP Address',
  hostname: 'Hostname',
  open_issues: 'Open Issues',
  action_taken: 'Action',
  comment: ''
}

export const statuses = [{'New': '#FFE133'}, {'In Progress': '#d0f31b'}, {'Waiting For Customer': '#FFA415'}, {'Closed': '#018D8D'}]

export const severities = {
  Critical: CriticalSeverity,
  High: HighSeverity,
  Important: ImportantSeverity,
  Moderate: ModerateSeverity,
  Medium: MediumSeverity,
  Low: LowSeverity,
}

export const filterConfig = [
  {
    Severity: {
      filter_name: "severity",
      isOpen: true,
      filters: {
        Critical: false, High: false, Medium: false, Low: false
      }
    },
  },
  {
    Exploit: {
      filter_name: "exploit",
      isOpen: true,
      filters: {'Yes': false, 'No': false}
    }
  },
  {
    New_Vulnerabilities: {
      filter_name: "new_vulnerabilities",
      isOpen: true,
      filters: {'Yes': false},
      title: 'Net New',
      tooltip: "Newly discovered vulnerabilities from the latest scan in comparison to the previous one"
    }
  }, {
    'Action Status': {
      filter_name: "action",
      isOpen: true,
      filters: {
        'New Risk': false,
        'Accept Risk': false,
        'Defer Risk': false,
        'Ignore': false,
        'In-Progress': false,
      }
    }
  }
]

export const resetFilterConfig = {
  Severity: {
    filter_name: "severity",
    isOpen: true,
    filters: {
      Critical: false, High: false, Medium: false, Low: false
    }
  },
  Exploit: {
    filter_name: "exploit",
    isOpen: true,
    filters: {'Yes': false, 'No': false}
  },
  New_Vulnerabilities: {
    filter_name: "new_vulnerabilities",
    isOpen: true,
    filters: {'Yes': false},
    title: 'Net New',
    tooltip: "Newly discovered vulnerabilities from the latest scan in comparison to the previous one"
  },
  'Action Status': {
    filter_name: "action",
    isOpen: true,
    filters: {
      'New Risk': false,
      'Accept Risk': false,
      'Defer Risk': false,
      'Ignore': false,
      'In-Progress': false,
    }
  }
}

export const actionOptions = [
  {'Accept Risk': false},
  {'Defer Risk': false},
  {'In-Progress': false},
  {'Ignore': false},
]

export const actionTooltipConfig = {
  'Accept Risk': 'Accept Vulnerability Risk',
  'Ignore': 'Customer Opt-Out: Ignoring Vulnerability',
  'Defer Risk': 'Deferring Fix: Accepting Vulnerability Risk',
  'In-Progress': 'Fixing Vulnerability',
  'Risk Mitigated': "Automatically Marked as Risk Mitigated. Updated when they are not found during subsequent scans. This process is automated and doesn't consider factors like offline assets or unscanned endpoints.",
  'New Risk': 'Automatically Marked as New Vulnerability'
}

export const radioConfig = [
  {
    name: "Change all",
    value: "Change all",
  },
  {
    name: "Keep selections made per asset",
    value: "Keep selections made per asset",
  },
]

export const tables = [
  {
    labels: ['CVE', 'Severity', 'Description', 'Exploit Status', 'First Discovered', 'Last Observed'],
    title: 'Summary'
  },
  {labels: ['Remediation', 'Remediation Level', 'Solution', 'Synopsis'], title: 'Recommendation'},
  {
    labels: ['CVSS Score',
      'CVSS V3 Base Score',
      'CVSS V3 Impact Score',
      'CVSS Vector',
      'Exploitability Score',
      "Exploit Ease",
      "See Also", "Risk Factor",
      "Check Type",
      "CVSS V2 Vector", "CVSS V2 Base Score",
      "CVSS V2 Temporal Score",
      "CVSS V3 Vector", "CVSS V3 Temporal Score",
      "Vuln Publication Date",
      "Family",
      "Port",
      "Protocol",
      "Findings", "CVSS V3 Score",
      "Exposed Machines",
      "Exploit URIs", "Exploit Types",
      "Public Exploit",
      "Exploit Verified",
    ],
    title: 'Additional info'
  },
];

export const updateAsset = async (value, rowIndex, selectedCheckboxes, selectActionFromDropdown, vuln_id, data, userData, isMultiSelect = false, setIsLoading) => {
  let singleValue = data[rowIndex]?.hostname || data[rowIndex]?.ipaddress;
  let idList = []
  if (isMultiSelect === true) {
    idList = []
    selectedCheckboxes.forEach((item, i) => {
      if (item === true) {
        let asset_name = data[i]?.hostname || data[i]?.ipaddress;
        idList.push(asset_name)
      }
    })
    selectActionFromDropdown(value)
  } else {
    idList = [singleValue]
    selectActionFromDropdown(rowIndex, value);
  }
  setIsLoading(true)
  try {
    await postUpdateActionOrComment(userData?.tenant, vuln_id, {asset_names: idList, action: value});
  } catch (e) {
    captureSentryError(e, userData, "postUpdateActionOrComment update action");
  } finally {
    setIsLoading(false)
  }
};
