export const severitiesValues = [
  {name: 'Critical', values: []},
  {name: 'High', values: []},
  {name: 'Medium', values: []},
  {name: 'Low', values: []},
  {name: 'Informational', values: []}
]

export const severityColors = {
  Critical: "#D64526",
  High: "#FFA415",
  Medium: "#FFE133",
  Low: "#638185",
  Informational: "#066666"
};

export const lineData = {
  lineOpacity: "1",
  lineOpacityHover: "1",
  otherLinesOpacityHover: "0.3",
  lineStroke: "3px",
  lineStrokeHover: "5px"
};

export const tabConfig = [{display: 'Arrival rate', configName: 'Arrival rate'},
  {display: 'Burn rate', configName: 'Burn rate'},
  {display: 'Escape rate', configName: 'Escape rate'}]

export const overviewMapping = [
  {
    data: 'total_vulnerabilities',
    title: 'VULNERABILITIES',
    subtitle: 'TOP RECOMMENDATIONS',
    headers: ['cve', 'description', 'cvss_score', 'assets'],
    tableData: 'top_10_recommendations'
  },
  {
    data: 'total_affected_assets',
    title: 'AFFECTED ASSETS',
    subtitle: 'MOST VULNERABLE ASSETS',
    headers: ['host', 'count'],
    tableData: 'top_10_affected_assets'
  }
];
