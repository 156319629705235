import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Auth, I18n } from "aws-amplify";
import {
  Authenticator,
  useAuthenticator,
  translations,
} from "@aws-amplify/ui-react";
import { formFields, headers, errors, i18n } from "./loginConfig";
import { getTenants } from "../../api/ApiClient";
import "../../styles/pages/Login/Login.scss";
import Router from "../../navigation/Router";
import UserData from "../../store/User/UserData";
import { BrowserStorageCache } from "@aws-amplify/cache";
import { isRoot } from "../../util/userControl";
import ErrorIcon from "@mui/icons-material/Error";
import { Loader } from "../../components/Loader/Loader";
import GlobalState from "../../store/GlobalState/GlobalState";
import { captureSentryError } from "../../util/sentry";
import App2FA from "./App2FA";
I18n.putVocabularies(translations);
I18n.setLanguage("en-US");
I18n.putVocabulariesForLanguage("en", i18n);

const Login = () => {
  const [userData, setUserData] = useContext(UserData);
  const { route } = useAuthenticator((context) => [context.route]);
  const { toResetPassword, toSignIn } = useAuthenticator();
  const [error, setError] = useState();
  const [loadingUserData, setLoadingUserData] = useState(true);
  const defaultRole = "Read_Only";
  const [azState] = useContext(GlobalState);
  const [setupMFA, setSetupMFA] = useState(true);
  const [cognitoUser, setCognitoUser] = useState(null);

  useEffect(() => {
    if (!BrowserStorageCache.getItem("hasRefreshed")) {
      BrowserStorageCache.setItem("hasRefreshed", true);
      setTimeout(() => window.location.reload(), 100); // delay refresh for 1Password to maintain input focus
    }
    return () => BrowserStorageCache.removeItem("hasRefreshed");
  }, []);

  useEffect(() => {
    async function getCognitoUserInfo() {
      const userInfo = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setCognitoUser(userInfo);
      if (userInfo.preferredMFA === "SOFTWARE_TOKEN_MFA") setSetupMFA(false);
    }
    !cognitoUser &&
      (route === "authenticated" || setupMFA) &&
      getCognitoUserInfo();
    // eslint-disable-next-line
  }, []);

  const formatTenants = (tenantItems) => {
    if (tenantItems && tenantItems.length > 0) {
      tenantItems = tenantItems.map((item) => ({
        label: item.name,
        value: item.id,
        id: item.id,
        name: item.id,
        role: item.role,
        is_onboarding: item.is_onboarding,
      }));
      tenantItems = tenantItems.sort((a, b) =>
        a.label < b.label ? -1 : a.label > b.label ? 1 : 0
      );
      tenantItems = tenantItems.sort((a, b) =>
        a.is_onboarding === b.is_onboarding ? 0 : !a.is_onboarding ? -1 : 1
      );
      return tenantItems;
    }
  };

  const getTenantList = async () => {
    try {
      let tenantItems = await getTenants();
      return formatTenants(tenantItems);
    } catch (aggregateError) {
      captureSentryError(aggregateError, userData, "getTenants in Login.js");
    }
  };

  useEffect(() => {
    const setCurrentSession = async () => {
      setLoadingUserData(true);
      try {
        const response = await Auth.currentSession();
        const { idToken } = response;
        const { sub: userId, email } = idToken.payload;
        const userTenants = await getTenantList();
        let selectedTenant =
          localStorage.getItem("tenant") || userTenants[0].value;
        let selectedTenantRole = defaultRole;
        let foundTenant = userTenants.filter(
          (item) => item.value === selectedTenant
        );
        // If the tenant in localStorage isn't in the user's tenants use the first tenant in the list
        if (foundTenant.length === 0) {
          selectedTenant = userTenants[0].value;
          selectedTenantRole = userTenants[0].role;
        } else {
          selectedTenantRole = foundTenant[0].role;
        }
        const isRootUser = isRoot(selectedTenantRole);
        let userType = isRootUser ? "root" : "tenant";
        let tenant = null;
        let gaTenant = null;
        if (selectedTenant) {
          tenant = selectedTenant;
          gaTenant = selectedTenant;
        }
        setUserData((state) => ({
          ...state,
          tenant,
          userType,
          userId,
          email,
          role: selectedTenantRole,
          userTenants: userTenants,
        }));
        localStorage.setItem("tenant", tenant);
        ReactGA.gtag("set", "user_properties", { Tenant: gaTenant });
      } catch (e) {
        setUserData((state) => ({
          ...state,
          tenant: "",
          userType: "",
          userId: "",
          email: "",
          role: "",
          userTenants: [],
        }));
      }
      setLoadingUserData(false);
    };
    setError();
    route === "authenticated" && !setupMFA && setCurrentSession();
    // eslint-disable-next-line
  }, [setUserData, route, setupMFA]);

  const components = {
    Header() {
      return <div></div>;
    },
    Footer() {
      return <div></div>;
    },
    Body() {
      return <div> </div>;
    },
  };

  const services = {
    async handleSignIn(formData) {
      BrowserStorageCache.clear(); // clear any previous user session
      setSetupMFA(true); // ensure MFA setup is required

      try {
        let { username, password } = formData;
        username = username.toLowerCase();

        const response = await Auth.signIn(username, password);
        if (response.challengeName === "SOFTWARE_TOKEN_MFA") {
          setSetupMFA(false);
          // This is a workaround for https://github.com/aws-amplify/amplify-ui/issues/1460
          formFields.setupTOTP.QR.totpUsername = username;
        } else {
          setCognitoUser(response);
        }

        return response;
      } catch (e) {
        formFields.setupTOTP.QR.totpUsername = "";
        e.name === "UserNotFoundException" ||
        e.name === "NotAuthorizedException"
          ? setError(errors.userNotFound)
          : setError(e.message);
        return Auth.currentAuthenticatedUser(); // allow another attempt
      }
    },

    async handleConfirmSignIn(formData) {
      BrowserStorageCache.clear(); // clear any previous user session
      const { user, code, mfaType } = formData;

      try {
        const response = await Auth.confirmSignIn(user, code, mfaType);
        formFields.setupTOTP.QR.totpUsername = "";
        return response;
      } catch (e) {
        e.name === "CodeMismatchException"
          ? setError(errors.invalidCode)
          : setError(e.message);
        return Auth.currentAuthenticatedUser(); // allow another attempt
      }
    },

    async handleForgotPassword(username) {
      try {
        const response = await Auth.forgotPassword(username);
        return response;
      } catch (e) {
        e.name === "UserNotFoundException"
          ? setError(errors.userNotFound)
          : setError(e.message);
        return Auth.currentAuthenticatedUser(); // allow another attempt
      }
    },

    async handleForgotPasswordSubmit(formData) {
      const { username, code, password } = formData;
      try {
        const response = await Auth.forgotPasswordSubmit(
          username,
          code,
          password
        );
        return response;
      } catch (e) {
        e.name === "InvalidParameterException"
          ? setError(errors.invalidPasswordFormat)
          : setError(e.message);
        return Auth.currentAuthenticatedUser(); // allow another attempt
      }
    },
  };

  const showRootLoader =
    (loadingUserData &&
      (route === "idle" || route === "authenticated") &&
      azState?.loadingIsParent === true &&
      !setupMFA) ||
    (route === "authenticated" && setupMFA && !cognitoUser);

  return showRootLoader ? (
    <div className="root-loader">
      <Loader />
    </div>
  ) : route === "authenticated" && !setupMFA ? (
    <Router />
  ) : (
    <div className={"login-container"}>
      <div
        className="bg-actzero"
        style={{ backgroundImage: `url('/mountain-scene.jpg')` }}
      >
        <div className={"breach-info"}>
          <p className={"breach-number"}>
            <span>BREACH?</span> 1-855-917-4981
          </p>
          <p
            className={"pointer breach-email"}
            onClick={() =>
              (window.location = "mailto:threathunting@actzero.ai")
            }
          >
            threathunting@actzero.ai
          </p>
        </div>
        <div className="mask-bg" />
      </div>
      <div className={"authenticator-container"}>
        <div className="logo">
          <img src="/ActZero-Logo_rev.svg" alt="ActZero" />
        </div>

        {route === "authenticated" && setupMFA ? (
          <div>
            <App2FA setSetupMFA={setSetupMFA} cognitoUser={cognitoUser} />
          </div>
        ) : (
          <>
            <div className={"header " + route}>{headers[route]}</div>
            <div className="subheader">
              {route === "signIn" &&
                "Please enter your credentials to proceed:"}
            </div>
            {error && (
              <div className="error-msg">
                <ErrorIcon className="err-icon" /> {error}
              </div>
            )}
            <Authenticator
              formFields={formFields}
              hideSignUp={true}
              submitButtonText="Login"
              components={components}
              className={route}
              services={services}
            />
            {route === "signIn" ? (
              <div className="reset-container">
                {"Forgot your password? "}
                <span className="link" onClick={toResetPassword}>
                  Reset password
                </span>
              </div>
            ) : (
              <div className="return-btn" onClick={toSignIn}>
                Back to Sign In
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
