import React, {useContext, useEffect, useState} from 'react';
import '../../../styles/pages/Vulnerabilities2/Overview.scss';
import VulnerabilityGraph from "./VulnerabilityGraph";
import {severitiesValues, severityColors} from "../config/overviewconfig";
import Trends from "./Trends";
import VulnerabilityTable from "./VulnerabilityTable";
import VulnerabilityTrendsGraph from "./VulnerabilityTrendsGraph";
import {captureSentryError} from "../../../util/sentry";
import UserData from "../../../store/User/UserData";
import {getOverviewData} from "../../../api/ApiClient";
import {Loader} from "../../../components/Loader/Loader";
import {overviewMapping} from "../config/overviewconfig";

const Overview = ({setCurrTab}) => {
  const [userData] = useContext(UserData);
  const [loading, isLoading] = useState(true)
  const [allData, setAllData] = useState({})

  useEffect(() => {
    const getData = async () => {
      isLoading(true)
      try {
        let response = await getOverviewData(userData.tenant);
        setAllData(response)
      } catch (e) {
        captureSentryError(e, userData, 'getOverviewData')
      } finally {
        isLoading(false)
      }
    }
    userData.tenant && getData();
    // eslint-disable-next-line
  }, [userData.tenant]);

  return (
    <div className={"overview-container"}>
      {loading ? <Loader/> : <>
        <div className={"left-panel"}>
          {overviewMapping.map((item, index) =>
            <div className={"data-panel"} key={index}>
              <p className={"value"}>{allData[item.data]}<span className={"label"}>{item.title}</span></p>
              <VulnerabilityTable data={allData[item.tableData]} mapData={item} setCurrTab={setCurrTab}/>
            </div>
          )}
        </div>
        <div className={"right-panel"}>
          <VulnerabilityGraph
            className={"severity"}
            values={severitiesValues}
            colors={severityColors}
            data={allData?.severity_trends}
          />
          <VulnerabilityTrendsGraph data={allData?.vulnerability_trends}/>
          {!Array.isArray(allData?.trends) && <Trends data={allData?.trends}/>}
        </div>
      </>}
    </div>
  );
}

export default Overview;
