import React, {useContext, useEffect, useState} from 'react';
import PageFilters from "../../../components/Filters/PageFilters";
import VulnerabilitiesTable from "../VulnerabilitiesTable";
import AssetsWithVulnerabilitiesRow from "./AssetsWithVulnerabilitiesRow";
import { filterConfig, tableHeader, resetFilterConfig } from "../config/assetswithvulnerabilitiesconfig";
import '../../../styles/pages/Vulnerabilities2/AssetsWithVulnerabilities/AssetsWithVulnerabilities.scss';
import UserData from "../../../store/User/UserData";
import { filterData } from "../../../util/format";
import { getAssetsAndDetailsTable } from "../../../api/ApiClient";
import { captureSentryError } from "../../../util/sentry";
import {useLocation, useNavigate} from "react-router-dom";
import { Loader } from "../../../components/Loader/Loader";
import { setSortFilterValue } from "../../../util/handleSortResults";
import { setResetFilter } from "../../../util/handleResetFilter";
import {useDebounce} from "../../../util/debounce";

const AssetsWithVulnerabilities = ({setCurrTab}) => {
  const [userData] = useContext(UserData);
  const [tableData, setTableData] = useState([]);
  const [isReset, setIsReset] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation()
  const defaultParams = {page: 1, size: 50}
  const [params, setParams] = useState(defaultParams)
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [filterList, setOpenFilters] = useState(filterConfig);
  const [page, setPage] = useState(1);
  const [sortFilters, setSortFilters] = useState([]);
  const debouncedParams = useDebounce(params, 1000, setIsLoading);

  useEffect(() => {
    filterData(filterList, params, 50, page, setParams, {}, () => {});
    // eslint-disable-next-line
  }, [filterList, page]);

  useEffect(() => {
    const getTableData = async () => {
      setIsLoading(true);
      try {
        if (location.state?.host) {
          params.asset_name = location.state.host
          delete location.state.host
        }
        setExpandedIndexes([]);
        const vulnerabilitiesTableResponse = await getAssetsAndDetailsTable(userData.tenant, debouncedParams);
        setTableData(vulnerabilitiesTableResponse);
      } catch (e) {
        captureSentryError(e, userData, 'getAssetsAndDetailsTable');
      } finally {
        setIsLoading(false);
        setIsReset(false)
      }
    };

    userData.tenant && getTableData()
    // eslint-disable-next-line
  }, [userData.tenant, debouncedParams, page]);

  const resetFilters = () => {
    setIsReset(true)
    setPage(1)
    setSortFilterValue([], tableHeader, setSortFilters);
    setExpandedIndexes([]);
    setResetFilter(filterConfig, resetFilterConfig, setOpenFilters);
    navigate('/risk-management/vulnerabilities2', { replace: true, state: null })
    setParams(defaultParams)
  };

  return (
    <div className={"assets-with-vulnerabilities-container fixed-table-container"}>
      <PageFilters
        type={"vulnerabilities"}
        isReset={isReset}
        filterList={filterList}
        className={"vulnerabilities-filters-new"}
        defaultDate={{ text: 'none' }}
        handleClose={() => setExpandedIndexes(null)}
        setOpenFilters={setOpenFilters}
        setParams={setParams}
        collapsible={true}
      />

      {!isLoading ? (
        <VulnerabilitiesTable
          setSortFilters={setSortFilters}
          sortFilters={sortFilters}
          resetFilters={resetFilters}
          page={page}
          setCurrTab={setCurrTab}
          setPage={setPage}
          expandedIndexes={expandedIndexes}
          setExpandedIndexes={setExpandedIndexes}
          tableData={tableData}
          className={"assets-and-details-table"}
          TableRow={AssetsWithVulnerabilitiesRow}
          tableHeader={tableHeader}
          params={params}
          setParams={setParams}
          exportType={'assets'}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default AssetsWithVulnerabilities;
