import React from "react";
import "../../styles/pages/Vulnerabilities2/Vulnerabilities/ActionComment.scss";
import Radio from "../../components/Radio/Radio";
import BetaIcon from "../../assets/icons/beta2.svg";
import Tooltip from "../../components/Tooltip/Tooltip";
import {vulnToggleTooltip} from "./config/tooltips";
import TooltipIcon from "../../assets/icons/tooltip.svg";

const TogglePage = ({onClick, defaultChecked}) => (
  <div className={"flex vuln-toggle"}>
    <Radio leftLabel={'1.0'} rightLabel={'2.0'} onClick={onClick} defaultChecked={defaultChecked}/>
    <img className="beta-icon" src={BetaIcon} alt="beta"/>
    <Tooltip content={vulnToggleTooltip} className={"title-tooltip"} direction={"left"}>
      <img className="tooltip-icon" src={TooltipIcon} alt="Instructions"/>
    </Tooltip>
  </div>
);

export default TogglePage;
